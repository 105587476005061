<script>
import palettesServices from '@/services/palettes.service'
import Palette from '@/data/models/Palette'
import EditLayout from '@/layout/EditLayout'

export default {
  name: 'SinglePalette',
  components: {
    EditLayout
  },
	data() {
		return {
      item: new Palette,
		}
  },
  computed: {
    id() {
      return this.$route.params.id
    },
    isNew() {
      return this.id === 'new'
    },
  },
	mounted() {
    !this.isNew && this.id && this._get()
	},
	methods: {
    _get(type = null) {
      palettesServices.getById({ id: this.id })
      .then(res => {
        this.item = res.data
      })
    },
    create() {
      palettesServices.create({ data: this.item })
        .then(_res => {
          this.$notify({
            message: this.$t('m.itemSuccessfullyAdded', { item: this.$tc('palette') }),
            icon: "far fa-bell",
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success',
          })
          this.$router.push({ name: 'palettes' })
        })
    },
    edit() {
      palettesServices.edit({ data: this.item })
        .then(_res => {
          this.$notify({
            message: this.$t('m.itemSuccessfullySaved', { item: this.$tc('palette') }),
            icon: "far fa-bell",
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success',
          })
        })
    },
	}
}
</script>

<template>
  <EditLayout 
    :item="item"
    :supports-editor="false"
    :submit-button-text="isNew ? $t('create') : $t('save')"
    @submit="isNew ? create() : edit()"
  />
</template>

<style lang="sass" scoped>
@import '~@/assets/sass/design.sass'

</style>
